html {
  overflow: hidden;
}

@font-face {
  font-family: "SegoeUI-Regular";
  src: url("../fonts/SegoeUI-Regular/SegoeUI-Regular.eot");
  src:
    url("../fonts/SegoeUI-Regular/SegoeUI-Regular.svg") format("svg"),
    url("../fonts/SegoeUI-Regular/SegoeUI-Regular.woff") format("woff"),
    url("../fonts/SegoeUI-Regular/SegoeUI-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SegoeUI-Semibold";
  src: url("../fonts/SegoeUI-Semibold/SegoeUI-Semibold.eot");
  src:
    url("../fonts/SegoeUI-Semibold/SegoeUI-Semibold.svg") format("svg"),
    url("../fonts/SegoeUI-Semibold/SegoeUI-Semibold.woff") format("woff"),
    url("../fonts/SegoeUI-Semibold/SegoeUI-Semibold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SegoeUI-Bold";
  src: url("../fonts/SegoeUI-Bold/SegoeUI-Bold.eot");
  src:
    url("../fonts/SegoeUI-Bold/SegoeUI-Bold.svg") format("svg"),
    url("../fonts/SegoeUI-Bold/SegoeUI-Bold.woff") format("woff"),
    url("../fonts/SegoeUI-Bold/SegoeUI-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  color: #262626;
  font-family: "SegoeUI-Regular", serif;
  font-size: 14px;
  background: #ebeef1;
}

.ui.visible.thin.left.sidebar ~ .fixed,
.ui.visible.thin.left.sidebar ~ .pusher {
  --webkit-transform: translate3d(300px, 0, 0) !important;
  transform: translate3d(300px, 0, 0) !important;
}

.ui.thin.left.sidebar,
.ui.thin.right.sidebar {
  width: 300px;
  background-color: #f4f5f7;
  box-shadow: 0 0 1px 1px #d1e6fb;
}
.pushable .ui.visible.overlay.sidebar ~ .pusher {
  padding-right: 300px;
  padding-left: 20px;
}

.ui.right.floated {
  float: right;
}
a {
  color: #091e42;
  text-decoration: none;
}

/*---------------------------------------------------------------Login------------------------------------------------*/
.login {
  width: 100%;
  background-size: cover;
  align-items: center;
  text-align: center;
}

.login h1 {
  font-size: 28px !important;
  font-weight: lighter !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.loginLogo {
  margin: 75px 0 75px 0;
  width: 50%;
}

.login .google {
  font-size: 16px !important;
  padding: 0 20px !important;
  width: 260px;
}

.login .sectionLeft {
  height: 100vh;
  background-size: cover;
  background-image: url("../images/cdp-practice-roles-hero@2x.jpg");
}

@media screen and (max-width: 425px) {
  .login .sectionLeft {
    display: none !important;
  }
}

/*-------------------------------------------------------------------Header-----------------------------------------*/
.logo {
  width: 150px;
  padding: 15px 0;
  margin-left: 10px;
}

.logoSmall {
  margin-left: 10px;
}

.clickable {
  cursor: pointer;
}

.float-left {
  float: left;
}

.width-100 {
  width: 100% !important;
}

.mt-60 {
  margin-top: 60px;
}

.main-menu {
  height: 80px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.leftMenu {
  border-top: 1px solid #eee;
  padding-top: 6px;
  float: left;
  width: 100%;
}

.leftMenu a {
  float: left;
  padding: 15px;
}

.login-with-google-btns {
  transition:
    background-color 0.3s,
    box-shadow 0.3s;
  cursor: pointer;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow:
    0 -1px 0 rgba(0, 0, 0, 0.04),
    0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family:
    -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}

.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
  color: #721c24;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.parentContainer {
  overflow: auto;
  margin-top: 80px;
  padding: 0.5em 1em 0 1em;
  height: calc(100vh - 80px);
}

.ui.dimmer {
  z-index: 1 !important;
  height: auto;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
}

.ui.basic.segment {
  padding: 0;
  margin: 0;
}

.ui.table {
  margin: 0 0 0.5em 0;
}

.page-heading {
  justify-content: space-between;
  display: flex;
}

.p-10 {
  padding: 10px !important;
}

.float-right {
  float: right !important;
}

.ml-10 {
  margin-left: 10px;
}

.cursor-pointer,
.cursorPointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.p-2 {
  padding: 2em;
}

.date-range {
  width: max-content;
}

.helpMark {
  display: inline-block;
}

.flex {
  display: flex;
}

.helpVideo {
  max-width: 100%;
}

.helpVideo + .helpVideo {
  margin-top: 20px;
}

.helpPdf iframe {
  width: 100%;
  min-height: 500px;
}
.helpPdf iframe + iframe {
  margin-top: 20px;
}

.helpList {
  padding-left: 15px;
}

.helpList li + li {
  margin-top: 15px;
  border-top: 1px solid #e1e1e1;
  padding-top: 10px;
}

.helpList li strong {
  font-size: 14px;
}

.helpList li span + span {
  margin-left: 30px;
}

.helpList li span:hover .linkText {
  text-decoration: underline;
}

.dashboard-segment {
  height: 40vh;
  overflow-y: auto;
}

/* --- start react-data-table-component ---*/
.gVslLF {
  display: flex;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
  min-height: 32px;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(250, 250, 250);
}

.jnmLLM {
  white-space: nowrap;
  font-weight: 400;
  min-width: 48px;
  flex: 0 0 48px;
}
.fKFIFa {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  word-break: break-word;
  padding: 0px;
}
/* --- end react-data-table-component ---*/

.form-inline-stacked-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.font-monospace {
  font-family: monospace !important;
  font-weight: 900 !important;
}

.ellipsis-50 {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mr-2 {
  margin-right: 0.3em;
}
